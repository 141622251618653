import ios14ChromeUrl from "../../../../blogs/assets/ios14-with-chrome.jpg";
import ios15ChromeUrl from "../../../../blogs/assets/ios15-with-chrome.jpg";
import ios14SafeAreaInsetBottomUrl from "../../../../blogs/assets/ios14-safe-area-inset-bottom.mp4";
import ios15SafeAreaInsetBottomUrl from "../../../../blogs/assets/ios15-safe-area-inset-bottom.mp4";
import * as React from 'react';
export default {
  ios14ChromeUrl,
  ios15ChromeUrl,
  ios14SafeAreaInsetBottomUrl,
  ios15SafeAreaInsetBottomUrl,
  React
};